import React from 'react';
import ModalHero from './modal_hero';
import ModalTitle from './modal_title';
import ModalDescription from './modal_description';
import ModalPermissionContainer from './modal_permission_container';
import ModalActionsContainer from './modal_actions_container';

export default class ModalInvite extends React.Component {
  render () {
    return (
      <div className="inner-modal-container">
        <ModalHero stage={this.props.stage} heroImagePath={this.props.heroImagePath} />
        <ModalTitle stage={this.props.stage} title={this.props.title} />
        <ModalDescription
          stage={this.props.stage}
          description={this.props.description}
          errorCode={this.props.errorCode}
          successCode={this.props.successCode}
          brand={this.props.brand}
          brandName={this.props.brandName}
          sourceMsManage={this.props.sourceMsManage}
          sourceMsManageMessages={this.props.sourceMsManageMessages}
          />
        <ModalPermissionContainer
          stage={this.props.stage}
          isBusinessAccount={this.props.isBusinessAccount}
          brand={this.props.brand}
          brandName={this.props.brandName}
          sourceMsManage={this.props.sourceMsManage}
          sourceMsManageMessages={this.props.sourceMsManageMessages}
          requestMessagePermissions={this.props.requestMessagePermissions}
          useFbLoginForBusinessPage={this.props.useFbLoginForBusinessPage}
          />
        <ModalActionsContainer
          page={this.props.page}
          stage={this.props.stage}
          fbStatus={this.props.fbStatus}
          brand={this.props.brand}
          brandName={this.props.brandName}
          usernameInput={this.props.usernameInput}
          username={this.props.username}
          userAvatarUrl={this.props.userAvatarUrl}
          isBusinessAccount={this.props.isBusinessAccount}
          fbPageCheckbox={this.props.fbPageCheckbox}
          sourceMsManage={this.props.sourceMsManage}
          sourceMsManageMessages={this.props.sourceMsManageMessages}
          alertRestartError={this.props.alertRestartError}
          errorCode={this.props.errorCode}
          successCode={this.props.successCode}
          handleAcceptInvite={this.props.handleAcceptInvite}
          handleGetStarted={this.props.handleGetStarted}
          handleManagedMessagesChoice={this.props.handleManagedMessagesChoice}
          handleFbConnect={this.props.handleFbConnect}
          handleViewProfile={this.props.handleViewProfile}
          handleUsernameInputChange={this.props.handleUsernameInputChange}
          handleFbLogout={this.props.handleFbLogout}
          handleFbPageCheckboxCheck={this.props.handleFbPageCheckboxCheck}
          useFbLoginForBusinessPage={this.props.useFbLoginForBusinessPage}
          buildLoginScopes={this.props.buildLoginScopes}
          isPersistingPreferences={this.props.isPersistingPreferences}
          />
      </div>
    )
  }
}
