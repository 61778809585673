import React from 'react';

export default class FacebookManagedMessagesDecision extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      grantManagedMessagesAccess: this.props.sourceMsManageMessages || false
    };
  }

  handleManagedMessagesChoice(event) {
    this.props.handleManagedMessagesChoice(event, this.state.grantManagedMessagesAccess);
  }

  handleManagedMessagesCheckbox() {
    this.setState({
      grantManagedMessagesAccess: !this.state.grantManagedMessagesAccess
    });
  }

  render() {
    return (
      <>
        <p className="custom-modal-subtitle">
          You can grant <strong>{this.props.brandName}</strong> access to stored DMs for enhanced features for <strong>{this.props.username}</strong>.
        </p>
        <div className="managed-message-choice-container">
          <label className="custom-modal-subtitle managed-message-checkbox-container">
            <input
              type="checkbox"
              className="form-checkbox"
              id="getstarted-checkbox"
              checked={this.state.grantManagedMessagesAccess}
              defaultChecked={this.state.grantManagedMessagesAccess}
              onChange={this.handleManagedMessagesCheckbox.bind(this)}
              disabled={this.props.isPersistingPreferences}
            />
            Allow <strong>{this.props.brandName}</strong> to view my DMs and respond as me on Instagram.
          </label>
        </div>
        <button
          className="custom-modal-primary-button custom-modal-input-item"
          onClick={this.handleManagedMessagesChoice.bind(this)}
          disabled={this.props.isPersistingPreferences}
          >
          Save Preference
        </button>
      </>
    );
  }
}
