import React from 'react';

export default class ModalPermissionContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPageDetails: false,
      showCommentDetails: false,
      showInsightDetails: false,
      showMessageDetails: false
    };
  }

  handleShowPageDetails = () => {
    this.setState({ showPageDetails: !this.state.showPageDetails });
  }

  handleShowCommentDetails = () => {
    this.setState({ showCommentDetails: !this.state.showCommentDetails });
  }

  handleShowInsightDetails = () => {
    this.setState({ showInsightDetails: !this.state.showInsightDetails });
  }

  handleShowMessageDetails = () => {
    this.setState({ showMessageDetails: !this.state.showMessageDetails });
  }

  render () {
    if (this.props.stage !== 4) {
      return null;
    }

    if (!this.props.isBusinessAccount && !this.props.useFbLoginForBusinessPage) {
      return null;
    }

    return (
      <ul className="modal-instagram-permissions">
        <li className="permission-item">
          <p>Pages Show List &amp; Business Management</p>
          <p className="subtle">We use these to find your connected page(s).</p>
          <a href="#"
            className={`permission-more-info-icon ${this.state.showPageDetails ? 'active-permission-dropdown' : ''}`}
            onClick={this.handleShowPageDetails}
          >
            <img
              src="/images/icons/question.svg"
              alt="question mark icon"
            />
          </a>
          <p className={this.state.showPageDetails ? 'subtle' : 'hidden'}>
            Due to the way Facebook connect works we need access to both of these to find the correct page(s) connected to your Instagram account.<br />
            We don't use them for anything else.
          </p>
        </li>
        <li className="permission-item">
          <p>Manage Instagram Comments</p>
          <p className="subtle">We use this to track relevant comments.</p>
          <a href="#"
            className={`permission-more-info-icon ${this.state.showCommentDetails ? 'active-permission-dropdown' : ''}`}
            onClick={this.handleShowCommentDetails}
          >
            <img
              src="/images/icons/question.svg"
              alt="question mark icon"
            />
          </a>
          <p className={this.state.showCommentDetails ? 'subtle' : 'hidden'}>
            MightyCheck will never post a comment without your permission.
          </p>
        </li>
        <li className="permission-item">
          <p>Manage Instagram Insights</p>
          <p className="subtle">We use this to find post & story metrics.</p>
          <a href="#"
            className={`permission-more-info-icon ${this.state.showInsightDetails ? 'active-permission-dropdown' : ''}`}
            onClick={this.handleShowInsightDetails}
          >
            <img
              src="/images/icons/question.svg"
              alt="question mark icon"
            />
          </a>
          <p className={this.state.showInsightDetails ? 'subtle' : 'hidden'}>
            This helps us understand post and story metrics like impressions and reach, as well as audience metrics like, age, gender, and location.
          </p>
        </li>
        <li className={this.props.requestMessagePermissions ? 'permission-item' : 'hidden permission-item'}>
          <p>Manage Instagram Messages</p>
          <p className="subtle">
            We need Messages access for real-time Story mention alerts.
            {this.props.brand ? ' Optionally, you can opt-in to storing DMs for enhanced features after this step.' : ''}
          </p>
          <a href="#"
            className={`permission-more-info-icon ${this.state.showMessageDetails ? 'active-permission-dropdown' : ''}`}
            onClick={this.handleShowMessageDetails}
          >
            <img
              src="/images/icons/question.svg"
              alt="question mark icon"
            />
          </a>
          <p className={this.state.showMessageDetails ? 'subtle' : 'hidden'}>
            Due to the way Facebook works, this permission is required for us to receive Story mention alerts for your Instagram account.
          </p>
        </li>
      </ul>
    );
  }
}
