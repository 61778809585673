import React from 'react'
import ReactDOM from 'react-dom'
import PropTyes from 'prop-types'

export default class ModalHero extends React.Component {
  render (){
    return (
      <div className="custom-modal-hero">
        <img src={this.props.heroImagePath} />
      </div>
    )
  }
}