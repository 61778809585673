import React from 'react';

export default class FacebookCustomLogin extends React.Component {
  render() {
    // (
    //   <div className="secondary-action-container">
    //     <a href="#">Not now</a>
    //   </div>
    // );

    return(
      <>
        <div className="fb-connect-button">
          <div className="avatar-ig-container">
            <div className="connect-ig-icon-container">
              <img src="/images/icons/ig.svg" />
            </div>
            <img src={this.props.userAvatarUrl} className="connect-ig-avatar" />
          </div>
          <button
            className="custom-modal-primary-button custom-modal-input-item fb-connect-btn"
            onClick={this.props.handleFbConnect}>
            Connect with Facebook
          </button>
        </div>
      </>
    );
  }
}
