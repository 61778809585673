import React from 'react'

//unnamed function - anonymous, parens instead of {} creates a return
export default class GetStartedForm extends React.Component {
  render () {
    let terms = (
      <a href="/terms" target="_blank">Terms</a>
    );

    let privacy = (
      <a href="/privacy" target="_blank">Privacy Policy</a>
    );

    let disabledVar = this.props.usernameInput && this.props.fbPageCheckbox ? false : true;

    let actionArea = null;

    if (this.props.useFbLoginForBusinessPage) {
      actionArea = (
        <button
          className="custom-modal-primary-button custom-modal-input-item"
          onClick={this.props.handleGetStarted}
        >
          Get Started
        </button>
      );
    } else {
      actionArea = (
        <>
          <div className="custom-modal-username-input">
            <img src="/images/icons/ig.svg" className="icon-label" />
            <input type="text" placeholder="Username" defaultValue={this.props.username} className="custom-modal-input-item custom-modal-text-input" onChange={this.props.handleUsernameInputChange} />
          </div>
          <button
            className="custom-modal-primary-button custom-modal-input-item"
            disabled={disabledVar}
            onClick={this.props.handleGetStarted}
          >
            Get Started
          </button>
          <p className="click-wrap text-align-left">
            <input
              type="checkbox"
              className="form-checkbox"
              id="getstarted-checkbox"
              defaultChecked={this.props.fbPageCheckbox}
              onChange={this.props.handleFbPageCheckboxCheck}
            />
            <p className="getstarted-checkbox-container">
            <label htmlFor="getstarted-checkbox" className="getstarted-label">
            I have a Facebook Page that is connected to my Instagram Creator/Business Account.
            </label>
            <span> </span>
            <a
              href={`/instagram-professional-account-setup${this.props.brand ? '?b=' + this.props.brand : ''}`}>
              Not Sure? Learn More Here
            </a>
            </p>
          </p>
        </>
      );
    }

    return(
      <>
        {actionArea}
        <p className="subtle click-wrap">By clicking Get Started, you agree to our {terms} and {privacy}.</p>
      </>
    );
  }
}
