import React from 'react';

export default class ModalDescription extends React.Component {
  render () {
    const noConnectedFbPageMessage = (
      <p className="extra-description">If you continue to run into issues, <a href={`/instagram-professional-account-setup${this.props.brand ? '?b=' + this.props.brand : ''}`}>view this tutorial</a> as a guide to check and try again.</p>
    );

    const errorMessageMap = {
      "no_connected_fb_page": noConnectedFbPageMessage
    };

    let successCodeDisplay = null;

    // Only show the success code for managed connections
    if (this.props.sourceMsManage) {
      if (this.props.successCode) {
        successCodeDisplay = (
          <p className="custom-modal-subtitle success-code-message">
            Please provide the following connection code to your account manager:<br />
            <input type="text" readOnly={true} value={this.props.successCode} />
          </p>
        );
      } else if (this.props.brand && this.props.stage === 1) {
        successCodeDisplay = (
          <p className="custom-modal-subtitle success-code-message">
            On success, you will need to take note of a 4 character connection code to provide to your account manager:<br />
            <img src="/images/mc-screens/connect-success.png" />
          </p>
        );
      }
    }

    return (
      <>
        <p className="custom-modal-subtitle">
          {this.props.description}
          {this.props.errorCode ? errorMessageMap[this.props.errorCode] : null }
        </p>
        {successCodeDisplay}
      </>
    );
  }
}
