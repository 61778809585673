import React from 'react'
import ReactDOM from 'react-dom'
import PropTyes from 'prop-types'

export default class ModalTitle extends React.Component {
  render (){
    if(!this.props.title){
      return null
    }
    return (
      <h4 className="custom-modal-title">{this.props.title}</h4>
    )
  }
}