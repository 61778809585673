import React from 'react';
import GetStartedForm from './get_started_form';
import FacebookCustomLogin from './facebook_custom_login';
import FacebookManagedMessagesDecision from './facebook_managed_messages_decision';

export default class ModalActions extends React.Component {
  handleRestartAndRetry() {
    this.props.handleFbLogout();
  }

  render () {
    let actionsToDisplay;

    //Might be good to use switch here Or state machine
    // if (this.props.stage == 1 && this.props.fbStatus == 'connected' && this.props.page == 'brand'){
    //   actionsToDisplay = (
    //     <button className="custom-modal-primary-button custom-modal-input-item" onClick={this.props.handleAcceptInvite}>
    //       Accept Invite
    //     </button>
    //   )
    if (this.props.alertRestartError) {
      actionsToDisplay = (
        <>
          <a
            href={`/troubleshoot${this.props.brand ? '?b=' + this.props.brand : ''}`}
            className="custom-modal-primary-button custom-modal-input-item">
            View Tutorial
          </a>
          &nbsp;
          <a
            href="/"
            className="custom-modal-primary-button custom-modal-input-item logout-btn"
            onClick={this.handleRestartAndRetry.bind(this)}
          >
            Restart & Retry
          </a>
        </>
      );
    } else if (this.props.useFbLoginForBusinessPage && this.props.stage == 3) {
      actionsToDisplay = null;
    } else if (this.props.stage == 1){
      actionsToDisplay = (
        <GetStartedForm
          usernameInput={this.props.usernameInput}
          username={this.props.username}
          handleGetStarted={this.props.handleGetStarted}
          handleUsernameInputChange={this.props.handleUsernameInputChange}
          brand={this.props.brand}
          fbPageCheckbox={this.props.fbPageCheckbox}
          handleFbPageCheckboxCheck={this.props.handleFbPageCheckboxCheck}
          useFbLoginForBusinessPage={this.props.useFbLoginForBusinessPage}
          />
      );
    } else if (this.props.isBusinessAccount && this.props.stage == 4){
      actionsToDisplay = (
        <FacebookCustomLogin
          userAvatarUrl={this.props.userAvatarUrl}
          handleFbConnect={this.props.handleFbConnect}
          />
      );
    } else if (!this.props.isBusinessAccount && this.props.stage == 4){
      actionsToDisplay = (
        <a
          href={`/instagram-professional-account-setup${this.props.brand ? '?b=' + this.props.brand : ''}`}
          className="custom-modal-primary-button custom-modal-input-item">
          View Tutorial
        </a>
        //TODO: Add back button/way to go back in case they potentially mispelled their username
      );
    } else if (this.props.stage == 5){
      if (this.props.sourceMsManage) {
        // Managed connections get an opt-in preference
        actionsToDisplay = (
          <FacebookManagedMessagesDecision
            brand={this.props.brand}
            brandName={this.props.brandName}
            username={this.props.username}
            sourceMsManageMessages={this.props.sourceMsManageMessages}
            handleManagedMessagesChoice={this.props.handleManagedMessagesChoice}
            isPersistingPreferences={this.props.isPersistingPreferences}
            />
        );
      } else {
        actionsToDisplay = (
          <button className="custom-modal-primary-button custom-modal-input-item" onClick={this.props.handleViewProfile}>View My Profile</button>
        );
      }
    } else if (this.props.errorCode === 'cant_fetch_any_media') {
      actionsToDisplay = (
        <a
          href={`/troubleshoot${this.props.brand ? '?b=' + this.props.brand : ''}`}
          className="custom-modal-primary-button custom-modal-input-item">
          View Tutorial
        </a>
      );
    } else {
      return null;
    }
    return (
      <div className="custom-modal-actions">
        {actionsToDisplay}
      </div>
    );
  }
}
